import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vedana---feeling",
      "style": {
        "position": "relative"
      }
    }}>{`Vedana - Feeling`}<a parentName="h1" {...{
        "href": "#vedana---feeling",
        "aria-label": "vedana   feeling permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/marlon_meditation_2.mp3" mdxType="AudioPlayer" />
    <p>{`We also invite you to listen to `}<a parentName="p" {...{
        "href": "https://sangha.live/dharma-library/mindfulness-of-feeling-tone-vedana/"
      }}>{`Mindfulness of Vedana with Martine Batchelor`}</a>{` for a slightly longer and more detailed mediation and discussion around this particular pillar.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      